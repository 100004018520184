import { legacyApiFetch } from "../utils/legacyApiFetch";
import { matchesUA } from "browserslist-useragent";

export default defineNuxtRouteMiddleware((to) => {
  if (to.path === "/notSupportedBrowser") {
    return undefined;
  }

  const browserSupported = useSessionStorage("browserSupported", 0);
  if (browserSupported.value !== 0) {
    return browserSupported.value === 2
      ? navigateTo("/notSupportedBrowser")
      : undefined;
  }

  if (!isBrowserSupported()) {
    browserSupported.value = 2;
    callApiBrowserNotSupported();
    return navigateTo("/notSupportedBrowser");
  }

  if (process.client) {
    browserSupported.value = 1;
  }

  return undefined;
});

function callApiBrowserNotSupported() {
  if (process.client) {
    try {
      legacyApiFetch(
        "api/browserNotSupported",
        function (errorCode: number | null) {
          if (errorCode) {
            console.error("browserNotSupported API call failed: ", errorCode);
          }
        },
      );
    } catch (error) {
      console.error("browserNotSupported API call failed: ", error);
    }
  } else {
    useApi("/api/browserNotSupported");
  }
}
const userAgent = computed(() => {
  const headers = useRequestHeaders(["user-agent"]);
  return headers["user-agent"];
});

function isBrowserSupported(): boolean {
  if (!process.client && !isSupportingBrowserVersion(userAgent.value)) {
    return false;
  }

  if (process.client && !testAllFeatures()) {
    return false;
  }

  return true;
}

function isSupportingBrowserVersion(
  userAgentString: string | undefined,
): boolean {
  if (userAgentString) {
    return matchesUA(userAgentString, {
      browsers: ["since 2018, not dead"],
    });
  }
  return false;
}
